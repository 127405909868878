import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import List from './components/shared/List';
import Container from './components/shared/Container';
import { store } from './redux/store';
import { loadCookieOptions, removeCookieOptions, saveCookieOptions } from './utils/cookies';
import { getUrlParams } from './utils/urlParams';
import { getOptions } from './utils/options';

export const renderApp = (container, containerId, options = { }) => {
  const root = ReactDOM.createRoot(document.getElementById(containerId));
  const queryOptions = getUrlParams(new URLSearchParams(window.location.search));
  const savedOptions = loadCookieOptions();
  const defaultOptions = {
    openUrlsInNewTab: true,
    ...getOptions(options, queryOptions, savedOptions),
  };
  if (queryOptions.reset === 'true') {
    removeCookieOptions();
  }
  saveCookieOptions(defaultOptions);
  if (container) {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <div>
            <Container containerId={containerId} prefix={defaultOptions.prefix}>
              <List options={defaultOptions} containerId={containerId} />
            </Container>
          </div>
        </Provider>
      </React.StrictMode>,
    );
    return true;
  }
  return false;
};
