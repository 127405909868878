import { useEffect, useState } from 'react';

export default function useResponsive(container) {
  const parentBlock = document.getElementById(container);
  const [parentWidth, setParentWidth] = useState(parentBlock.clientWidth);
  useEffect(() => {
    const handleResize = () => {
      if (parentBlock) {
        const newWidth = parentBlock.clientWidth;
        setParentWidth(newWidth);
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Initial width measurement
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return parentWidth;
}
