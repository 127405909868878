import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useGetWidgetsQuery } from '../../../redux/services/widgetApi';
import RectangleListItem from '../../RectangleWidget/RectangleListItem';
import RoundedListItem from '../../RoundedWidget/RoundedListItem';
import RoundedListItemSmall from '../../RoundedWidget/RoundedListItemSmall';
import PromotionalRectangleListItem from '../../PromotionalRectangleWidget/PromotionalRectangleListItem';

function objectToQueryString(obj) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    params.append(key, obj[key]);
  });
  return params.toString();
}

export default function List({ options, containerId }) {
  const urlPathname = window.location.pathname;
  const queryOptions = objectToQueryString({ ...options, urlPathname });
  const { data, loading } = useGetWidgetsQuery(queryOptions);
  const [stateOptions, setStateOptions] = useState(options);
  useEffect(() => {
    if (data?.options) {
      setStateOptions((prev) => ({ ...prev, ...data.options }));
    }
  }, [data]);

  if (loading && !data) return null;

  const renderWidgetItem = (item) => {
    const commonProps = {
      key: item.id,
      title: item.title,
      options: stateOptions,
      containerId,
      themeConfig: data?.options?.themeConfig,
    };
    if (options.widgetFormat === 'rectangle' || options.widgetFormat === 'rectangleReverse' || options.widgetFormat === 'rectangleLong') {
      return (
        <RectangleListItem
          image={options.widgetFormat === 'rectangleReverse' ? item.squareImage : item.image}
          partner={item.brand}
          url={item.url}
          {...commonProps}
        />
      );
    }

    if (options.widgetFormat === 'rounded') {
      return (
        <RoundedListItem
          description={item.description}
          image={item.image}
          callToAction={item.callToAction}
          timeToComplete={item.timeToComplete}
          incentive={item.incentive}
          type={item.type}
          incentiveCallToAction={item.incentiveCallToAction}
          url={item.url}
          {...commonProps}
        />
      );
    }
    if (options.widgetFormat === 'roundedSmall') {
      return (
        <RoundedListItemSmall
          description={item.description}
          image={item.image}
          callToAction={item.callToAction}
          timeToComplete={item.timeToComplete}
          incentive={item.incentive}
          type={item.type}
          incentiveCallToAction={item.incentiveCallToAction}
          url={item.url}
          {...commonProps}
        />
      );
    }
    if (options.widgetFormat === 'promotionalRectangle') {
      return (
        <PromotionalRectangleListItem
          image={options.widgetFormat === 'rectangleReverse' ? item.squareImage : item.image}
          partner={item.brand}
          url={item.url}
          callToAction={item.callToAction}
          description={item.description}
          {...commonProps}
        />
      );
    }
    return null;
  };
  return (
    <div className={cn(options.widgetFormat !== 'promotionalRectangle' && `${options.prefix}grid ${options.prefix}gap-5 ${options.prefix}justify-center ${options.prefix}grid-cols-[repeat(auto-fit,_minmax(0,_400px))]`)}>
      {data?.items.slice(0, options.widgetCount || 4).map(renderWidgetItem)}
    </div>
  );
}
