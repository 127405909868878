import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './features/theme.slice';
import { widgetApi } from './services/widgetApi';

export const store = configureStore({
  reducer: {
    themeReducer,
    [widgetApi.reducerPath]: widgetApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(widgetApi.middleware),
});
