import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../config.ts';

export const widgetApi = createApi({
  reducerPath: 'widgets',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getWidgets: builder.query({
      query: (options) => `/offers/?${options}`,
    }),
  }),
});
export const { useGetWidgetsQuery } = widgetApi;
