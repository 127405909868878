const optionKeys = [
  'partnerId',
  'widgetId',
  'widgetFormat',
  'userId',
  'clickId',
  'email',
  'country',
  'zip',
  'age',
  'birthdate',
  'gender',
  'clickSub',
  'source',
  'offerId',
  'reset',
  'previewId',
  'url',
  'referer',
  'prefix',
  'widgetCount',
  'offerNumber',
];

export const getOptions = (options = {}, urlOptions = {}, cookieOptions = {}) => {
  const result = {};
  optionKeys.forEach((key) => {
    const value = urlOptions[key] || options[key] || cookieOptions[key];
    if (result[key] === 'partnerId' && value === undefined) {
      result[key] = 1;
    }
    if (value) {
      result[key] = value;
    }
    if (key === 'prefix' && value === '') {
      result[key] = '';
    }
    if ((key === 'prefix' && value === undefined) || value === 'rakis-widget-') {
      result[key] = 'rakis-widget-';
      const scriptElement = document.createElement('script');

      scriptElement.innerHTML = `
      tailwind.config = {
        prefix: 'rakis-widget-',
      }
      `;
      document.head.appendChild(scriptElement);
    }
  });
  return result;
};
