import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'light',
  widgetFormat: 'rectangle',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setWidgetFormat: (state, action) => {
      state.widgetFormat = action.payload;
    },
  },
});
export const { setTheme, setWidgetFormat } = themeSlice.actions;
export default themeSlice.reducer;
