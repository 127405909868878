import cn from 'classnames';
import useResponsive from '../../../hooks/useResponsive';

export default function Image({
  src, containerId, widgetFormat, prefix,
}) {
  const containerWidth = useResponsive(containerId);
  return (
    <div className={cn(containerWidth > 768 ? `${prefix}w-[48px] ${prefix}h-[48px]` : widgetFormat === 'rectangleLong' ? `${prefix}w-[160px] ${prefix}h-[90px]` : `${prefix}w-[90px] ${prefix}h-[90px]`)}>
      <img className={cn(`${prefix}w-full ${prefix}h-full ${prefix}rounded ${prefix}object-cover`)} src={src} alt="" />
    </div>
  );
}
