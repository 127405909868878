import cn from 'classnames';
import Image from '../Image';
import Title from '../Title';
import Description from '../Description';
import Footer from '../Footer';
import Metrics from '../Metrics';
import Button from '../Button';
import { getFont } from '../../../utils/getFont';
import { updateUrlQueries } from '../../../utils/getWidgetLink';

export default function RoundedListItemSmall({
  image,
  callToAction,
  title,
  description,
  themeConfig,
  timeToComplete,
  incentive,
  type,
  incentiveCallToAction,
  options,
  url,
}) {
  return (
    <a
      href={updateUrlQueries(url, options)}
      target={options.openUrlsInNewTab ? '_blank' : '_self'}
      className={`${options.prefix}max-w-[168px] ${options.prefix}rounded-lg ${options.prefix}flex ${options.prefix}flex-col ${options.prefix}overflow-hidden ${options.prefix}bg-skin-base ${options.prefix}shadow-[0_4px_8px_0_rgba(15,29,102,0.06)]`}
      rel="noreferrer"

    >
      <Image
        src={image}
      />
      <div className={cn(
        `${options.prefix}p-2 ${options.prefix}flex ${options.prefix}flex-col ${options.prefix}h-full ${options.prefix}justify-between`,
        {
          'pb-2': `sm:${options.prefix}pb-5`,
          [`${options.prefix}text-[${themeConfig?.textColor}]`]: themeConfig?.textColor,
        },
      )}
      >
        <div>
          <Title
            title={title}
            prefix={options.prefix}
            className={cn({
              [`${options.prefix}font-${getFont(themeConfig?.fonts?.heading?.fontFamily)}`]: themeConfig?.fonts?.heading?.fontFamily,
              [`${options.prefix}font-[${themeConfig?.fonts?.heading?.fontWeight}]`]: themeConfig?.fonts?.heading?.fontWeight,
              'text-[12px]': true,
            })}
          />
          <Description
            description={description}
            prefix={options.prefix}
            className={cn({
              [`${options.prefix}font-${getFont(themeConfig?.fonts?.body?.fontFamily)}`]: themeConfig?.fonts?.body?.fontFamily,
              [`${options.prefix}font-[${themeConfig?.fonts?.body?.fontWeight}]`]: themeConfig?.fonts?.body?.fontWeight,
            })}
          />
        </div>

        <Footer prefix={options.prefix}>
          <Metrics prefix={options.prefix}>
            {timeToComplete || incentive ? (
              ((timeToComplete && type !== 'offer') && (
              <div>
                <div className={`${options.prefix}text-nowrap`}>
                  ⏱&nbsp;
                  {timeToComplete}
        &nbsp;Min
                </div>
              </div>
              ))
            ) : null}

            {incentive && !timeToComplete ? (
              (
                <div className={`${options.prefix}truncate`}>
                  <span role="img" aria-label="fire">
                    🔥
                  </span>
    &nbsp;&nbsp;
                  {incentiveCallToAction}
                </div>
              )
            ) : null}

          </Metrics>

        </Footer>
      </div>
    </a>
  );
}
