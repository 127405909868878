import cn from 'classnames';
import { twMerge } from 'tailwind-merge';
import Image from '../Image';
import Title from '../Title';
import Partner from '../Partner';
import Button from '../Button';
import { getFont } from '../../../utils/getFont';
import { updateUrlQueries } from '../../../utils/getWidgetLink';
import useResponsive from '../../../hooks/useResponsive';

export default function RectangleListItem({
  image, title, partner, themeConfig, options, url, containerId,
}) {
  const containerWidth = useResponsive(containerId);
  return (
    <div className={`${options.prefix}w-full ${options.prefix}pb-4 ${options.prefix}border-b ${options.prefix}border-[#dadada]`}>
      <a
        target={options.openUrlsInNewTab ? '_blank' : '_self'}
        href={updateUrlQueries(url, options)}
        className={cn(
          `${options.prefix}flex ${options.prefix}gap-1 ${options.prefix}justify-between`,
          containerWidth > 768 ? `${options.prefix}flex-row` : `${options.prefix}flex-col`,

        )}
        rel="noreferrer"
      >
        <div className={cn(`${options.prefix}flex ${options.prefix}gap-1.5 ${options.prefix}flex-1 ${options.prefix}group`, { [`${options.prefix}flex-row-reverse`]: options?.widgetFormat === 'rectangleReverse' })}>
          <div className={`${options.prefix}flex ${options.prefix}flex-col ${options.prefix}items-center ${options.prefix}gap-2`}>
            <Image
              src={image}
              containerId={containerId}
              widgetFormat={options?.widgetFormat}
              prefix={options.prefix}
            />
          </div>
          <div className={cn(
            `${options.prefix}flex ${options.prefix}flex-col ${options.prefix}gap-2 ${options.prefix}flex-1 ${options.prefix}w-full ${options.prefix}items-stretch 
            ${options?.widgetFormat === 'rectangleReverse' ? `${options.prefix}justify-start ${options.prefix}gap-0` : `${options.prefix}justify-between`}`,
            containerWidth > 768 && `${options.prefix}h-full`,
            { [`${options.prefix}text-[${themeConfig?.textColor}]`]: themeConfig?.textColor },
          )}
          >
            <div className={cn(
              `${options.prefix}flex ${options.prefix}flex-col ${options.prefix}justify-between ${options.prefix}gap-2`,
              containerWidth > 768 && `${options.prefix}h-full`,
              { 'gap-3': options?.widgetFormat === 'rectangleLong' },
            )}
            >
              <Title
                title={title}
                prefix={options.prefix}
                className={cn({
                  [`${options.prefix}font-${getFont(themeConfig?.fonts?.heading?.fontFamily)}`]: themeConfig?.fonts?.heading?.fontFamily,
                  [`${options.prefix}font-[${themeConfig?.fonts?.heading?.fontWeight}]`]: themeConfig?.fonts?.heading?.fontWeight,
                  [`${options.prefix}text-[${themeConfig?.fonts?.heading?.textColor}]`]: themeConfig?.fonts?.heading?.textColor,
                  [`${options.prefix}text-[${themeConfig?.fonts?.heading?.fontSize}]`]: '12px',
                })}
              />
              <Partner
                partner={partner}
                prefix={options.prefix}
                className={cn({
                  [`${options.prefix}font-${getFont(themeConfig?.fonts?.body?.fontFamily)}`]: themeConfig?.fonts?.body?.fontFamily,
                  [`${options.prefix}font-[${themeConfig?.fonts?.body?.fontWeight}]`]: themeConfig?.fonts?.body?.fontWeight,
                  [`${options.prefix}text-[${themeConfig?.fonts?.heading?.fontSize}]`]: themeConfig?.fonts?.heading?.fontSize,
                })}
              />
            </div>
            <Button
              partner={partner}
              target={options.openUrlsInNewTab ? '_blank' : '_self'}
              href={updateUrlQueries(url, options)}
              widgetFormat={options?.widgetFormat}
              widgetId={options?.widgetId}
              prefix={options.prefix}
              className={cn(
                `${options.prefix}flex`,
                containerWidth > 768 && `${options.prefix}hidden`,
                {
                  [`${options.prefix}text-[${themeConfig?.buttonTextColor}]`]: themeConfig?.buttonTextColor,
                  [`${options.prefix}text-[${themeConfig?.buttonFontSize}]`]: !!themeConfig?.buttonFontSize,
                  [`hover:${options.prefix}text-[${themeConfig?.buttonTextColorHover}]`]: themeConfig?.buttonTextColorHover,
                  [`${options.prefix}bg-[${themeConfig?.buttonBackground}]`]: themeConfig?.buttonBackground,
                  [`hover:${options.prefix}bg-[${themeConfig?.buttonBackgroundHover}]`]: !!themeConfig?.buttonBackgroundHover,
                  [`${options.prefix}font-${getFont(themeConfig?.fonts?.cta?.fontFamily)}`]: themeConfig?.fonts?.cta?.fontFamily,
                  [`${options.prefix}font-[${themeConfig?.fonts?.cta?.fontWeight}]`]: themeConfig?.fonts?.cta?.fontWeight,
                  [`${options.prefix}border-${themeConfig?.buttonBorder === 'none' ? 'none' : `[${themeConfig?.buttonBorder}]`}`]: themeConfig?.buttonBorder,
                  [`hover:${options.prefix}border-${themeConfig?.buttonBorder === 'none' ? 'none' : `[${themeConfig?.buttonBorder}]`}`]: themeConfig?.buttonBorderHover,
                },
                themeConfig?.buttonBorder === 'none' ? '' : `${options.prefix}border`,
                options?.widgetFormat === 'rectangleReverse' && options?.widgetFormat === '2' ? `${options.prefix}p-0 ${options.prefix}justify-start ${options.prefix}text-start` : '',
              )}
            />
          </div>
        </div>
        <div className={twMerge(cn(
          `${options.prefix}flex ${options.prefix}justify-between ${options.prefix}items-center ${options.prefix}gap-1`,
          containerWidth > 768 && `${options.prefix}flex-col`,
          { 'justify-center': options?.widgetFormat === 'rectangleReverse' },
        ))}
        >
          <Button
            partner={partner}
            target={options.openUrlsInNewTab ? '_blank' : '_self'}
            href={updateUrlQueries(url, options)}
            widgetFormat={options?.widgetFormat}
            prefix={options.prefix}
            widgetId={options?.widgetId}
            className={cn(
              containerWidth > 768 ? `${options.prefix}flex` : `${options.prefix}hidden`,
              {
                [`${options.prefix}text-[${themeConfig?.buttonTextColor}]`]: themeConfig?.buttonTextColor,
                [`${options.prefix}text-[${themeConfig?.buttonFontSize}]`]: !!themeConfig?.buttonFontSize,
                [`hover:${options.prefix}text-[${themeConfig?.buttonTextColorHover}]`]: themeConfig?.buttonTextColorHover,
                [`${options.prefix}bg-[${themeConfig?.buttonBackground}]`]: themeConfig?.buttonBackground,
                [`hover:${options.prefix}bg-[${themeConfig?.buttonBackgroundHover}]`]: !!themeConfig?.buttonBackgroundHover,
                [`${options.prefix}font-${getFont(themeConfig?.fonts?.cta?.fontFamily)}`]: themeConfig?.fonts?.cta?.fontFamily,
                [`${options.prefix}font-[${themeConfig?.fonts?.cta?.fontWeight}]`]: themeConfig?.fonts?.cta?.fontWeight,
                [`${options.prefix}border-${themeConfig?.buttonBorder === 'none' ? 'none' : `[${themeConfig?.buttonBorder}]`}`]: themeConfig?.buttonBorder,
                [`hover:${options.prefix}border-${themeConfig?.buttonBorder === 'none' ? 'none' : `[${themeConfig?.buttonBorder}]`}`]: themeConfig?.buttonBorderHover,
              },
              themeConfig?.buttonBorder === 'none' ? '' : 'border',
              options?.widgetFormat === 'rectangleReverse' && options?.widgetFormat === '2' ? `${options.prefix}p-0 ${options.prefix}justify-start ${options.prefix}text-start` : '',
            )}
          />
        </div>
      </a>

    </div>
  );
}
