import Cookies from 'js-cookie';

const CLIENT_COOKIES = 'a_cprm';

export const removeCookieOptions = () => Cookies.remove(CLIENT_COOKIES, { path: '/' });

export const saveCookieOptions = ({ prefix, ...rest }) => {
  Cookies.set(CLIENT_COOKIES, JSON.stringify(rest));
};

export const loadCookieOptions = () => {
  const cookie = Cookies.get(CLIENT_COOKIES);
  return !cookie ? {} : JSON.parse(cookie);
};
