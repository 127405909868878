import cn from 'classnames';
import useResponsive from '../../../hooks/useResponsive';

export default function PromotionalRectangleListItem({
  image, title, partner, themeConfig, options, url, containerId, callToAction, description,
}) {
  const containerWidth = useResponsive(containerId);

  function isArrayString(str) {
    if (str.trim().startsWith('[') && str.trim().endsWith(']')) {
      try {
        const parsedData = JSON.parse(str);
        return Array.isArray(parsedData);
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  if (description === '') {
    description = '[]';
  }
  let promotionals;
  if (isArrayString(description)) {
    promotionals = Array.isArray(JSON.parse(description)) ? JSON.parse(description) : [];
  } else {
    promotionals = description;
  }
  return (
    <div className={cn(`${options.prefix}flex ${options.prefix}gap-10 ${options.prefix}pb-4`, containerWidth < 875 && `${options.prefix}w-full`)}>
      <div className={`${options.prefix}w-48 ${options.prefix}flex ${options.prefix}flex-col ${options.prefix}gap-2`}>
        <img className={cn(` ${options.prefix}object-contain`, containerWidth < 875 && `${options.prefix}h-fit`)} src={image} alt="" />
      </div>
      <div className={`${options.prefix}max-w-sm`}>
        <h3 className={cn(`${options.prefix}font-bold`, containerWidth < 875 && `${options.prefix}text-sm`)}>{title}</h3>
        {
          Array.isArray(promotionals) ? (
            <ol className={cn(`${options.prefix}list-disc ${options.prefix}ml-5 ${options.prefix}list-none`, containerWidth < 875 && `${options.prefix}mb-4`)}>
              {promotionals && promotionals.map((value) => (
                <li className={cn(containerWidth < 875 && `${options.prefix}text-xs`)} key={value}>
                  {value}
                </li>
              ))}
            </ol>
          )
            : <p>{promotionals}</p>
        }
        {containerWidth < 875 && (
          <div className={`${options.prefix}flex ${options.prefix}items-center`}>
            <a
              href={url}
              className={cn(
                `${options.prefix}max-h-12 !${options.prefix}bg-[#CF333E] ${options.prefix}h-2/4 ${options.prefix}w-48 ${options.prefix}font-bold ${options.prefix}text-white ${options.prefix}rounded-md ${options.prefix}flex ${options.prefix}justify-center ${options.prefix}items-center`,
                containerWidth < 875 && `${options.prefix}w-48`,
              )}
              type="button"
            >
              {callToAction}
            </a>
          </div>
        )}
      </div>
      {containerWidth > 875 && (
        <div className={`${options.prefix}flex ${options.prefix}items-center`}>
          <a
            href={url}
            className={cn(
              `${options.prefix}max-h-12 !${options.prefix}bg-[#CF333E] ${options.prefix}h-2/4 ${options.prefix}w-48 ${options.prefix}font-bold ${options.prefix}text-white ${options.prefix}rounded-md ${options.prefix}flex ${options.prefix}justify-center ${options.prefix}items-center`,
              containerWidth < 875 && `${options.prefix}w-28`,
            )}
            type="button"
            target="_blank"
            rel="noreferrer"
          >
            {callToAction}
          </a>
        </div>
      )}
    </div>
  );
}
