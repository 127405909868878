import { getUrlParams } from './urlParams';

const urlParams = new URLSearchParams(window.location.search);
const getPartnerUrl = () => {
  const { pathname } = window.location;
  const matchedPathname = pathname.match(/(\/partners\/\d+)/);
  if (matchedPathname && matchedPathname[0]) {
    return matchedPathname[0];
  }
  return '';
};

export const updateUrlQueries = (url, options) => {
  if (!url) return null;

  const sourceParam = urlParams.get('source');
  const offerIdParam = urlParams.get('offerId');
  const partnerIdParam = urlParams.get('partnerId');
  const previewIdParam = urlParams.get('previewId');

  const updatedSearch = {};
  const split = url.split('?');
  const urlPath = split[0];
  const urlSearch = split[1];
  if (!urlSearch) {
    return url;
  }

  const urlQueries = getUrlParams(urlSearch);
  // eslint-disable-next-line no-restricted-syntax
  for (const key in urlQueries) {
    // eslint-disable-next-line no-prototype-builtins
    if (urlQueries.hasOwnProperty(key)) {
      const matched = urlQueries[key].match(/\{(.*)\}/);
      if (matched && matched[1] && options[matched[1]]) {
        updatedSearch[key] = options[matched[1]];
      }
      if (!matched) {
        updatedSearch[key] = urlQueries[key];
      }
      if (offerIdParam) {
        updatedSearch.aff_unique5 = offerIdParam;
      }
      if (previewIdParam) {
        updatedSearch.previewId = previewIdParam;
      }
      if (urlQueries.source === 'redirect' || sourceParam === 'redirect') {
        if (urlQueries[key] === '{partnerId}') {
          updatedSearch.aff_id = 2;
        }
        updatedSearch.aff_sub = partnerIdParam || options.partnerId;
      }
    }
  }

  return `${getPartnerUrl()}${urlPath}?${new URLSearchParams(updatedSearch).toString()}`;
};
