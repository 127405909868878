import { twMerge } from 'tailwind-merge';

export default function Button({
  partner, className, href, target, widgetFormat, widgetId, prefix,
}) {
  return (

    <a href={href} target={target || '_self'} className={twMerge(`${prefix}text-[#e71d1d] ${prefix}flex ${prefix}items-center hover:${prefix}bg-[#e71d1d] hover:${prefix}text-white ${prefix}ease-out ${prefix}duration-300 ${prefix}text-sm ${prefix}font-bold ${prefix}h-9 ${prefix}rounded ${className} ${widgetId === '2' ? `${prefix}px-0 ${prefix}text-start` : `${prefix}px-3 ${prefix}text-center ${prefix}justify-center`}`)}>

      <p className={`${prefix}line-clamp-1 ${widgetFormat === 'rectangleReverse' ? `${prefix}h-[24px]` : `${prefix}h-[19px]`}`}>
        See at
        {partner}
      </p>
    </a>
  );
}
