export default function Button({
  children,
  className,
  href,
  target,
  prefix,
  ...props
}) {
  return <a href={href} rel="noreferrer" target={target || '_blank'} className={`${prefix}rounded-[30px] ${prefix}truncate ${prefix}self-end ${prefix}flex ${prefix}items-center ${prefix}h-[40px] ${prefix}text-sm ${prefix}text-white ${prefix}font-bold ${prefix}bg-[#cf333e] ${prefix}py-3 ${prefix}px-3 ${className}`} {...props}>{children}</a>;
}
