import './index.css';

import { renderApp } from './App';

window.rks.callMethod = (functionName = 'init', ...args) => {
  if (functionName) {
    window.rks[functionName].apply(null, args);
  }
};

// Re init

window.rks.init = (containerId = 'widget', options = {}) => {
  if (containerId) {
    renderApp(document.getElementById(containerId), containerId, options);
  }
};

const start = () => {
  if (window.rks.queue && window.rks.queue.length) {
    while (window.rks.queue.length) {
      window.rks.callMethod.apply(null, window.rks.queue[0]);
      window.rks.queue.shift();
    }
  }
};

start();
